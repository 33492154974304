import model, { startTimer } from './model';
import { checkSiteIsTemplateByInstance, getEndDate } from './helper';
import moment, { Moment } from 'moment-timezone';
import {
  COUNTDOWN_CLOCK_STATE,
  CurrentState,
  END_MESSAGE_STATE,
  SPINNER,
} from '../constants';
import webBiLogger from '@wix/web-bi-logger';
import { countdownClockNavigation } from '@wix/bi-logger-wixlabs-users/v2';
import { CurrentStateValue } from '../Types';

export default model.createController(
  ({ $widget, flowAPI, $w, appData, controllerConfig, widgetConfig }) => {
    let timeoutId;
    let intervalId;
    const instanceId = controllerConfig.appParams.instanceId;
    const compId = controllerConfig.compId;
    const localStorage = flowAPI.controllerConfig.platformAPIs.storage.local;
    const removeVisitorsLocalStorage = (countDownType: string) => {
      if (countDownType !== 'repeat_per_visitor') {
        const savedData = getSaveData();
        if (savedData && savedData.length > 0) {
          localStorage.removeItem(
            `countdown-repeat-per-visitor-${instanceId}${compId}`,
          );
        }
      }
    };
    const getSaveData = () => {
      try {
        const savedData = JSON.parse(
          localStorage.getItem(
            `countdown-repeat-per-visitor-${instanceId}${compId}`,
          ) || '[]',
        );
        return savedData;
      } catch (e) {
        console.log(e);
      }
    };

    const { onLogin } = controllerConfig.wixCodeApi.user;
    const getLoggedInDateByID = () => {
      const { currentUser } = controllerConfig.wixCodeApi.user;
      const savedData = getSaveData();
      if (savedData) {
        const isUserWasAdded = savedData
          .map((element) => element.id)
          .indexOf(currentUser.id);
        if (isUserWasAdded === -1) {
          savedData.push({ id: currentUser.id, loggedInTime: moment() });
          localStorage.setItem(
            `countdown-repeat-per-visitor-${instanceId}${compId}`,
            JSON.stringify(savedData),
          );
          return moment();
        } else {
          return savedData[isUserWasAdded].loggedInTime;
        }
      }
    };

    onLogin((data) => {
      const { countDownType } = $widget.props;
      if (countDownType === 'repeat_per_visitor') {
        const savedData = getSaveData();
        if (savedData) {
          const isUserWasAdded = savedData
            .map((element) => element.id)
            .indexOf(data.id);
          if (isUserWasAdded === -1) {
            savedData.push({ id: data.id, loggedInTime: moment() });
            localStorage.setItem(
              `countdown-repeat-per-visitor-${instanceId}${compId}`,
              JSON.stringify(savedData),
            );
          }
          handleStartTimer($widget.props);
        }
      }
    });
    const handleStartTimer = (countdownProps) => {
      const {
        daysShowInElementsPanel_countdown,
        hoursShowInElementsPanel_countdown,
        minutesShowInElementsPanel_countdown,
        secondsShowInElementsPanel_countdown,
        showEndMessage,
        countDownType,
      } = countdownProps;
      removeVisitorsLocalStorage(countDownType);
      const res = getEndDate(countdownProps, getLoggedInDateByID);
      let countdownEndDate: Moment | undefined = res.countdownEndDate;
      const isTemplateSite = checkSiteIsTemplateByInstance(
        flowAPI.controllerConfig.appParams.instance,
      );
      if (
        isTemplateSite &&
        (countdownEndDate === undefined || countdownEndDate.diff(moment()) <= 0)
      ) {
        countdownEndDate = moment().add(1, 'day');
      }

      const remainingTimeToStart = res.remainingTimeToStart;
      timeoutId && clearTimeout(timeoutId);
      intervalId && clearInterval(intervalId);
      timeoutId = setTimeout(() => {
        intervalId = setInterval(() => {
          if (
            countdownEndDate === undefined ||
            countdownEndDate.diff(moment()) <= 0
          ) {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
          } else {
            const { isViewer, isPreview } = flowAPI.environment;
            if (
              $w('#multiStateBox1').currentState.id !== COUNTDOWN_CLOCK_STATE &&
              (isViewer || isPreview)
            ) {
              $w('#multiStateBox1').changeState(COUNTDOWN_CLOCK_STATE);
            }
          }
          startTimer(
            $w('#timer1').displayRemainingTime,
            daysShowInElementsPanel_countdown,
            hoursShowInElementsPanel_countdown,
            minutesShowInElementsPanel_countdown,
            secondsShowInElementsPanel_countdown,
            countdownEndDate,
            () => endTimeLogic(showEndMessage),
          );
        }, 1000);
      }, Math.max(remainingTimeToStart, 0));

      if (remainingTimeToStart > 0) {
        startTimer(
          $w('#timer1').displayRemainingTime,
          daysShowInElementsPanel_countdown,
          hoursShowInElementsPanel_countdown,
          minutesShowInElementsPanel_countdown,
          secondsShowInElementsPanel_countdown,
          moment(),
          () => endTimeLogic(showEndMessage),
        );
      }
    };
    const endTimeLogic = (showEndMessage) => {
      const { isViewer, isPreview } = flowAPI.environment;
      if (isViewer || isPreview) {
        if (showEndMessage && (isViewer || isPreview)) {
          $w('#multiStateBox1').changeState(END_MESSAGE_STATE);
        } else {
          $w('#multiStateBox1').changeState(COUNTDOWN_CLOCK_STATE);
        }
      } else {
        handleStateInEditor($widget.props.currentTabValue);
      }
    };
    const handleStateInEditor = (currentTabValue) => {
      const { isViewer, isPreview } = flowAPI.environment;
      if (!isViewer && !isPreview) {
        if (currentTabValue === 'Tab 1') {
          if ($w('#multiStateBox1').currentState.id !== COUNTDOWN_CLOCK_STATE) {
            $w('#multiStateBox1').changeState(COUNTDOWN_CLOCK_STATE);
            countDownNavigationBiEvent(CurrentState.Clock);
          }
        } else {
          if ($w('#multiStateBox1').currentState.id !== END_MESSAGE_STATE) {
            $w('#multiStateBox1').changeState(END_MESSAGE_STATE);
            countDownNavigationBiEvent(CurrentState.End_Message);
          }
        }
      }
    };
    $widget.onPropsChanged((oldProps, newProps) => {
      if (JSON.stringify(oldProps) !== JSON.stringify(newProps)) {
        handleStartTimer(newProps);
        handleStateInEditor(newProps.currentTabValue);
        setTitle(newProps);
      }
    });
    const setTitle = (props) => {
      const { countdownTitle, endMessageTitle } = props;
      const { t } = flowAPI.translations;
      if (countdownTitle === '') {
        $w('#text1').text = t('app.countdown.title');
      } else {
        $w('#text1').text = countdownTitle;
      }
      if (endMessageTitle === '') {
        $w('#text2').text = t('app.countdown.endMessage.title');
      } else {
        $w('#text2').text = endMessageTitle;
      }
    };
    const countDownNavigationBiEvent = (currenTState: CurrentStateValue) => {
      const logger = webBiLogger.factory().logger();
      const { appParams, compId } = controllerConfig;
      const { appInstanceId, instanceId } = appParams;
      logger.report(
        countdownClockNavigation({
          app_site_id: appInstanceId,
          comp_id: compId,
          instance_id: instanceId,
          biToken: controllerConfig?.platformAPIs?.bi?.metaSiteId,
          uuid: controllerConfig?.platformAPIs?.bi?.ownerId,
          view: currenTState,
        }),
      );
    };
    const showLoader = async () => {
      try {
        await $w('#multiStateBox1').changeState(SPINNER);
      } catch (e) {
        console.log('error on load', e);
      }
    };
    return {
      pageReady: async () => {
        const { t } = flowAPI.translations;
        $widget.fireEvent('widgetLoaded', {});
        await showLoader();
        setTitle($widget.props);
        if ($w('#button1').label === '') {
          $w('#button1').label = t('app.countdown.buyNow.button');
        }
        if ($w('#button2').label === '') {
          $w('#button2').label = t('app.countdown.endMessage.button');
        }
        handleStartTimer($widget.props);
        handleStateInEditor($widget.props.currentTabValue);
      },
      updateWidgetViewState: (viewStateId) => {
        if (viewStateId === END_MESSAGE_STATE) {
          $w('#multiStateBox1').changeState(END_MESSAGE_STATE);
          countDownNavigationBiEvent(CurrentState.End_Message);
        } else {
          $w('#multiStateBox1').changeState(COUNTDOWN_CLOCK_STATE);
          countDownNavigationBiEvent(CurrentState.Clock);
        }
      },
      exports: {},
    };
  },
);
