import { WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';
import moment from 'moment-timezone';

export const countdownProps = {
  countDownType: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'no_repeat',
  },
  no_repeat_endDateTimer: {
    type: WidgetPropertyType.STRING,
    defaultValue: new Date(),
  },
  no_repeat_endTime: {
    type: WidgetPropertyType.STRING,
    defaultValue: '00:00',
  },
  timeZone: {
    type: WidgetPropertyType.STRING,
    defaultValue: moment.tz.guess(),
  },
  repeat_type: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'daily_countdown',
  },
  daily_endTime: {
    type: WidgetPropertyType.STRING,
    defaultValue: '23:59',
  },
  daily_startTime: {
    type: WidgetPropertyType.STRING,
    defaultValue: '00:00',
  },
  weekly_startTime: {
    type: WidgetPropertyType.STRING,
    defaultValue: '00:00',
  },
  weekly_endTime: {
    type: WidgetPropertyType.STRING,
    defaultValue: '00:00',
  },
  weekly_startDay: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 0,
  },
  weekly_endDay: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 6,
  },
  monthly_startTime: {
    type: WidgetPropertyType.STRING,
    defaultValue: '00:00',
  },
  monthly_endTime: {
    type: WidgetPropertyType.STRING,
    defaultValue: '00:00',
  },
  monthly_startDay: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 1,
  },
  monthly_endDay: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 31,
  },
  per_visitor_time_Unit: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'hours',
  },
  per_visitor_amount_of_time: {
    type: WidgetPropertyType.NUMBER,
    defaultValue: 20,
  },
  currentTabValue: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'Tab 1',
  },
  timerEndDate: {
    type: WidgetPropertyType.STRING,
    defaultValue: '12/12/2024  11:11',
  },
  showEndMessage: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
  },
  daysShowInElementsPanel_countdown: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  hoursShowInElementsPanel_countdown: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  minutesShowInElementsPanel_countdown: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  secondsShowInElementsPanel_countdown: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  countdownTitleShowInElementsPanel_countdown: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  countdownButtonShowInElementsPanel_countdown: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  endMessageTitleShowInElementsPanel_countdown: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  endMessageButtonShowInElementsPanel_countdown: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  countdownTitle: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
  },
  endMessageTitle: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
  },
  isSeparatorsCollapsed: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: false,
  },
};
