import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import { timerProps } from './timerProps';

/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'Timer1',
  props: timerProps,
  methods: {
    // Here you can define your widget public props
    displayRemainingTime: {
      params: [{ name: 'params', description: '' }],
    },
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
