import { WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export const timerProps = {
  daysLabel: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'app.settings.elementsPanel.categories.time.unit.days',
  },
  hoursLabel: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'app.settings.elementsPanel.categories.time.unit.hours',
  },
  minutesLabel: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'app.settings.elementsPanel.categories.time.unit.minutes',
  },
  secondsLabel: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'app.settings.elementsPanel.categories.time.unit.seconds',
  },
  showLabels: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  showSeparators: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  animationType: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
  },
};
