import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import moment from 'moment-timezone';
import { countdownProps } from './countdownProps';

/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/

function pad2(number) {
  return (number < 10 && number >= 0 ? '0' : '') + number;
}

export const startTimer = (
  displayRemainingTime,
  daysShowInElementsPanel,
  hoursShowInElementsPanel,
  minutesShowInElementsPanel,
  secondsShowInElementsPanel,
  timerEndDate,
  endTimeLogic = () => {},
) => {
  getTimeRemaining({
    displayRemainingTime,
    daysShowInElementsPanel,
    hoursShowInElementsPanel,
    minutesShowInElementsPanel,
    secondsShowInElementsPanel,
    timerEndDate,
    endTimeLogic,
  });
};
const getTimeRemaining = ({
  displayRemainingTime,
  daysShowInElementsPanel,
  hoursShowInElementsPanel,
  minutesShowInElementsPanel,
  secondsShowInElementsPanel,
  timerEndDate,
  endTimeLogic,
}) => {
  const t = timerEndDate.diff(moment());
  let days = Math.trunc(t / (1000 * 60 * 60 * 24));
  let hours = Math.trunc((t / (1000 * 60 * 60)) % 24);
  if (daysShowInElementsPanel === false) {
    hours = hours + days * 24;
  }
  let minutes = Math.trunc((t / 1000 / 60) % 60);
  if (!hoursShowInElementsPanel) {
    minutes = minutes + hours * 60;
  }
  let seconds = Math.trunc((t / 1000) % 60);
  if (!minutesShowInElementsPanel) {
    seconds = seconds + minutes * 60;
  }
  if (days > 99) {
    // $widget.fireEvent('daysAlert')
  }
  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    // $widget.fireEvent('dayZero')
    endTimeLogic();
    days = 0;
    minutes = 0;
    hours = 0;
    seconds = 0;
  }
  const daysValue = pad2(days);
  const hoursValue = pad2(hours);
  const minutesValue = pad2(minutes);
  const secondsValue = pad2(seconds);

  const daysData = pad2(days)
    .split('')
    .map((digit, index) => {
      return {
        _id: index + '',
        value: digit,
      };
    });
  const hoursData = pad2(hours)
    .split('')
    .map((digit, index) => {
      return {
        _id: index + '',
        value: digit,
      };
    });
  const minutesData = pad2(minutes)
    .split('')
    .map((digit, index) => {
      return {
        _id: index + '',
        value: digit,
      };
    });
  const secondsData = pad2(seconds)
    .split('')
    .map((digit, index) => {
      return {
        _id: index + '',
        value: digit,
      };
    });
  displayRemainingTime({
    daysValue,
    hoursValue,
    minutesValue,
    secondsValue,
    daysData,
    hoursData,
    minutesData,
    secondsData,
  });
  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    // $widget.fireEvent('dayZero')
    return;
  }
};

export default createBlocksModel({
  widgetName: 'Countdown',
  props: countdownProps,
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
